<template>
  <BaseModalSmall
    @close="close"
    v-bind:isValidated="true"
    @confirm="confirmYes"
    :noSlide="true"
    split-buttons="true"
    @cancel="confirmNo"
  >
    <template v-slot:header>Professional Executors</template>
    <template v-slot:body>
      <div>
        <p class="highlight">
          The professional executor service is provided by the team at
          <span>
            {{ alternativeProfessionalExecutors.company_name }} of
            {{ alternativeProfessionalExecutors.address_line_1 }},
            {{ alternativeProfessionalExecutors.address_line_2 }},
            {{ alternativeProfessionalExecutors.address_line_3 }}.</span
          >
        </p>

        <p>
          Would you like them to forward you information on the service they
          provide?
        </p>
      </div>
    </template>
    <template v-slot:button> Continue </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'AlternativeProfessionalExecutorsModal',
  components: {
    BaseModalSmall
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirmYes() {
      this.$store.commit('details', {
        consent_to_alternative_professional_executors: true
      })
      this.$emit('confirm')
      this.$store.dispatch('saveDetails')
    },
    confirmNo() {
      this.$store.commit('details', {
        consent_to_alternative_professional_executors: false
      })
      this.$emit('confirm')
      this.$store.dispatch('saveDetails')
    }
  },
  computed: {
    alternativeProfessionalExecutors() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.broker &&
        this.$store.getters.checkoutBasket.coupon.broker
          .alternative_professional_executors
      )
        return this.$store.getters.checkoutBasket.coupon.broker
          .alternative_professional_executors
      else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.highlight {
  padding: 15px;
  background-color: #faf9fe;
  color: #2f2c2c;
}
h5 {
  font-weight: 600;
  margin-top: 35px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
p {
  margin: 0 0 0.75em;
  line-height: 1.7em;
  font-size: 18px;
}
</style>

<template>
  <BaseModalIntro
    v-if="showValue"
    :introTitle="'Executors'"
    :showValue="showValue"
    :show="show"
    @close="$emit('close')"
    @doNotShowAgain="doNotShowAgain"
  >
    <template v-slot:contents>
      <h5>Overview</h5>

      <p>
        An executor is a person named in a Will to carry out the wishes of the
        deceased person. Amongst other things their role is to locate, manage
        and disburse the assets of the estate. Being appointed as an executor
        can be both an honor and an obligation depending on the complexity of
        the estate and the issues that can arise.
      </p>

      <h5>Responsibilities</h5>

      <p>
        Executors have a number of duties and responsibilities, they will have
        to:
      </p>

      <ul>
        <li>
          <p>Inform institutions where the deceased held assets;</p>
        </li>
        <li>
          <p>
            Obtain valuations of assets held by the deceased at the date of
            death;
          </p>
        </li>
        <li>
          <p>Ascertain the extent of any debts and liabilities;</p>
        </li>
        <li>
          <p>
            Complete the inheritance tax returns and pay any inheritance tax
            due;
          </p>
        </li>
        <li>
          <p>File any necessary tax returns;</p>
        </li>
        <li>
          <p>Collect the assets of the deceased;</p>
        </li>
        <li>
          <p>Pay the debts and liabilities of the estate;</p>
        </li>
        <li>
          <p>
            Transfer assets to the beneficiaries, pay legacies and distribute
            the estate;
          </p>
        </li>
        <li>
          <p>Prepare detailed estate accounts.</p>
        </li>
      </ul>

      <h5>Choices</h5>

      <p>
        You will need to appoint a minimum of one Executor. If you decide to
        have only one Executor, it is advisable to have a substitute in place.
        This way, if the Executor does not wish to act or is unable to do so,
        your substitute can take over. Keep in mind that the role of Executor
        comes with a significant amount of responsibility as Executors can be
        held financially liable for any errors that have been made during the
        administration of an Estate.
      </p>
    </template>
  </BaseModalIntro>
</template>

<script>
import BaseModalIntro from '@/common/ui/BaseModalIntro'

export default {
  name: 'ExecutorsIntroModal',
  components: {
    BaseModalIntro
  },
  props: ['show'],
  computed: {
    showValue() {
      return this.$store.getters.showIntroModals.executors
    }
  },
  methods: {
    doNotShowAgain() {
      this.$store.commit('showIntroModals', { executors: false })
    }
  }
}
</script>

<style></style>

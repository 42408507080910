<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionLarge
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-executor-professional.png'"
        :letter="'a'"
        :selected="executorProfessional"
        :option="'Professional Executors'"
        @selected="saveProfessional"
      >
        <template v-slot:text>
          Our professional executors have all of the resources and experience
          necessary to deal with the administration of your estate; providing
          you with total peace of mind.
        </template>
      </InputOptionLarge>

      <InputOptionLarge
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-pro-fam.png'"
        :letter="'b'"
        :selected="executorProfessionalAndFamily"
        :option="'Friends or Family with Professional Executors'"
        @selected="saveProfessionalAndFamily"
      >
        <template v-slot:text>
          A combination of lay executors (family members or friends) and
          professionals could be ideal. The professionals can deal with taxes,
          accounts and other more complex aspects of the administration, whilst
          family can deal with your funeral and everything else.
        </template>
      </InputOptionLarge>

      <InputOptionLarge
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-executor-non-professional.png'"
        :letter="'c'"
        :selected="
          executorProfessional === false &&
          executorProfessionalAndFamily === false
        "
        :option="'Friends and Family Only'"
        @selected="saveFamily"
      >
        <template v-slot:text>
          If you are appointing non-professionals, you may wish to consider
          close family members or friends. They can obtain professional advice
          if they find the process too difficult or time-consuming, but
          ultimately they are in charge.
        </template>
      </InputOptionLarge>
      <AlternativeProfessionalExecutorsModal
        v-show="show.alternativeExecutorsModal"
        @close="show.alternativeExecutorsModal = false"
        @confirm="$router.push(forwardTo).catch((e) => console.log(e))"
      />

      <IntroModal
        v-show="show.intro"
        :show="show.intro"
        @close="show.intro = false"
      ></IntroModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        @forwardClick="forward"
        v-bind:backLink="'/home'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionLarge from '@/common/ui/InputOptionLarge'
import IntroModal from '../IntroModal'
import AlternativeProfessionalExecutorsModal from '@/views/pages/ExecutorsPage/Questions/Helpers/AlternaiveProfessionalExecutorsModal'

export default {
  name: 'ExecutorsTypeOf',
  components: {
    AlternativeProfessionalExecutorsModal,
    NavQuestion,
    NavQuestionButtons,
    InputOptionLarge,
    IntroModal
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    people() {
      return this.$store.getters.people
    },
    executorProfessional() {
      return this.$store.getters.details.executor_professional
    },
    executorProfessionalAndFamily() {
      return this.$store.getters.details.executor_professional_and_family
    },
    isValidated() {
      if (this.executorProfessional !== null) return true
      return false
    },
    forwardTo() {
      if (this.executorProfessional) return '/executors/check_and_confirm'
      else if (this.executorProfessionalAndFamily) return '/executors/primary'
      else if (this.executorProfessional === false && this.partner)
        return '/executors/partner'
      else if (this.executorProfessional === false && !this.partner)
        return '/executors/primary'
      return '/executors/primary'
    },
    alternativeProfessionalExecutors() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.broker &&
        this.$store.getters.checkoutBasket.coupon.broker
          .alternative_professional_executors
      )
        return this.$store.getters.checkoutBasket.coupon.broker
          .alternative_professional_executors
      else {
        return false
      }
    }
  },
  data() {
    return {
      show: {
        intro: true,
        alternativeExecutorsModal: false
      },
      loading: false,
      number: '1',
      heading: 'What type of executors would you like to appoint?',
      subHeading:
        'Choosing Executors can be difficult as the role carries with it a considerable amount of legal, tax and administrative responsibility. ' +
        'If the estate is uncomplicated, relatives or family friends could be suitable. Appointing professionals will remove the stress and strain that a ' +
        'family member or friend could be subjected to; particularly for more complex estates.'
    }
  },
  methods: {
    saveFamily() {
      this.$store.commit('details', {
        executor_professional: false,
        executor_professional_and_family: false
      })
      this.resetExecutors()
    },
    saveProfessional() {
      this.$store.commit('details', {
        executor_professional: true,
        executor_professional_and_family: false
      })
      this.resetExecutors()
    },
    saveProfessionalAndFamily() {
      this.$store.commit('details', {
        executor_professional: false,
        executor_professional_and_family: true
      })
      this.resetExecutors()
    },
    resetExecutors() {
      this.people.forEach((person) => {
        let personCopy = Object.assign({}, person)
        personCopy.executor_sole_primary = false
        personCopy.executor_primary = false
        personCopy.executor_secondary = false
        personCopy.executor_backup = false
        this.$store.commit('personAdd', personCopy)
      })
      this.$store.commit('details', {
        executors_backup_required: false
      })
    },
    forward() {
      if (
        this.alternativeProfessionalExecutors &&
        (this.executorProfessional || this.executorProfessionalAndFamily)
      ) {
        this.show.alternativeExecutorsModal = true
      } else {
        this.$router.push(this.forwardTo).catch((e) => console.log(e))
      }
    }
  }
}
</script>

<style></style>
